import React from "react"
import BlogHeader from "../../blog_header/blog_header"
import * as classess from "../blogs_styling.module.css"
import hire_banner from "./static/hire.png"
import hire_banner_webp from "./static/hire_w.webp"
import biases from "./static/Biases.png"
import biases_webp from "./static/Biases_w.webp"
import slide1 from "./static/slide1.png"
import slide1_webp from "./static/slide1.webp"
import slide3 from "./static/slide3.png"
import slide3_webp from "./static/slide3.webp"
import slide4 from "./static/slide4.png"
import slide4_webp from "./static/slide4.webp"
import slide5 from "./static/slide5.png"
import slide5_webp from "./static/slide5.webp"
import technology from "./static/technology.png"
import technology_webp from "./static/technology_w.webp"
import Scroll from "../scroll/scroll"

import Footer from "../../../footer/footer"
import Social from "../../../social/social"

const BiasesInHirings = props => {
  return (
    <React.Fragment>
      <Scroll showBelow={250} />
      <BlogHeader title="How to Prevent  Top 10 Biases in Hiring Process" />
      <div className={classess.blog_content_main}>
        <center>
          <picture>
            <source srcSet={hire_banner_webp} type="image/webp" />
            <source srcSet={hire_banner} type="image/png" />
            <img
              srcSet={hire_banner_webp}
              alt="Main Image"
              loading="lazy"
              className={classess.main_image_styling}
            />
          </picture>
        </center>
        {/*<h1 className={classess.heading_one}>
          How to Prevent Top 10 Biases in Hiring Process
  </h1>*/}

        {/* <center>
                <picture>
                    <source srcSet={conversational_banner_webp} type="image/webp" />
                    <source srcSet={conversational_banner} type="image/png" />
                    <img srcSet={conversational_banner_webp} alt="Main Image" loading="lazy" className={classess.main_image_styling} />
                </picture>
            </center> */}

        {/* <div className={classess.content_styling}>
          What are the top 10 biases in hiring systems. How to prevent biases in
          Pakistan’s hiring process. Learn more.
        </div>*/}
        <h1 className={classess.heading_one}>
          How to Prevent Bias in Hiring Process
        </h1>
        <h2 className={classess.heading_two}>
          Biases Prevalent In Pakistan’s Hiring System
        </h2>
        {/* <center>
                <picture>
                    <source srcSet={conversational_recruiting_webp} type="image/webp" />
                    <source srcSet={conversational_recruiting} type="image/png" />
                    <img srcSet={conversational_recruiting_webp} alt="Main Image"loading="lazy" className={classess.main_image_styling} />
                </picture>
            </center> */}

        <div className={classess.content_styling}>
          Inclusivity and diversity are the focus points for organizations all
          around the globe these days - no one wants to be associated with
          discrimination of any form. Companies are actively seeking to recruit
          a much more diverse pool of candidates, not only because they are
          socially expected to, but because their contribution to the workplace
          is much more valued today. And for good reason, because why would
          someone want ten identical perspectives on one matter when they can
          get ten different ones? Despite the huge change in mindset that is
          taking the world under its wing, there are still so many places where
          the shift has only begun and the conventional hiring biases still
          exist in the minds of recruiters (whether it is conscious or
          unconscious is a whole other matter). Even in Pakistan, the concept of
          this bias-free work zone has been introduced as a concept, but the
          implementation is yet to be carried out in its true sense.
          <br />
          Lucky for us though, Botnostic Solution’s RecruitmentBot understands
          the damaging role bias plays within the hiring process and we aim to
          combat that problem through our state of the art technology. We
          understand that bias is not always and necessarily the purposeful,
          evil thing everyone makes it out to be. It can be unconscious. It can
          be a mistake. It can exist in any shape or form because everywhere
          there is the human touch, there is bias. This is why our screening
          system is aimed at eliminating bias at the very first stage of the
          hiring process by testing clients in accordance to skills and
          compatibility and whatever tailored requirements your job may have so
          that you can find the best match, bias and error-free.
        </div>

        {/* <div className={classess.content_styling}>
           Conversational Recruiting is an important part of RecruitmentBot’s screening strategy. We aim to create an environment that is both professional and relaxed to ensure that candidates always perform their best. We understand how intimidating the hiring process can be from the candidates’ end and how tiresome it can be from the employer’s end, which is why we have come up with the perfect solution: RecruitmentBot, that asks all the right questions and get the information hiring managers to need, but through a system that is designed to accommodate candidates and make sure they perform their absolute best. 
            </div> */}

        <h2 className={classess.heading_two}>What are Biases?</h2>
        <center>
          <picture>
            <source srcSet={biases_webp} type="image/webp" />
            <source srcSet={biases} type="image/png" />
            <img
              srcSet={biases_webp}
              alt="Main Image"
              loading="lazy"
              className={classess.main_image_styling}
            />
          </picture>
        </center>

        <div className={classess.content_styling}>
          Before carrying on with descriptions, we want to make sure you’re on
          the same page as us. What exactly is a bias? A bias is an unfair
          inclination in favour (or against) one person, a group, or even a
          belief. Have you ever wondered why you can tolerate a bad joke made by
          your friend but not one made by a stranger? Well, that’s because you
          are biased in favour of your friend! Your preexisting emotions and
          ideas about your friend tend to influence your thought process - and
          that is exactly what a bias is. Not all biases are subconscious, some
          can even be learned, but in either case, a bias is a flaw in judgement
          - a systematic error.
        </div>

        <h2 className={classess.heading_two}>
          10 Types of Hiring Biases in Pakistani Hiring Systems
        </h2>

        <h3 className={classess.heading_three}>
          1. Sticking With What you Believe in
        </h3>
        <div className={classess.content_styling}>
          We are all guilty of passing judgement and forming opinions almost way
          too quickly, and once the opinion is formed we do whatever is in our
          power to justify our decision. This is where the confirmation bias
          comes in; only considering proofs that support your snap decision and
          ignoring all the signs that go against it is called the confirmation
          bias. Although it sounds like a convenient way of doing things, acting
          upon this during the hiring process is setting yourself up for a lot
          of trouble in the future.
        </div>
        <div className={classess.content_styling}>
          The reason behind this is that people place too much trust in their
          instincts and then do whatever they can to support them. Opinions are
          formed in a split second, sometimes even before the interview can
          begin, and this is why a lot of Pakistani recruiters miss out on great
          candidates for no valid reason at all.
        </div>
        <h3 className={classess.heading_three}>
          2. Stereotyping is Something We All Do
        </h3>
        <center>
          <picture>
            <source srcSet={slide3_webp} type="image/webp" />
            <source srcSet={slide3} type="image/png" />
            <img
              srcSet={slide3_webp}
              alt="Main Image"
              loading="lazy"
              className={classess.main_image_styling}
            />
          </picture>
        </center>
        <div className={classess.content_styling}>
          Stereotyping is the practice of overly generalizing a group of people
          and assuming they are all much more alike than they truly are. The
          reason behind this is that people are lazy. Why put in so much effort
          learning about someone or something at an individual level when there
          already exists an opinion you can easily adopt? However, employers can
          not (and should not) take the risk of doing this in the hiring process
          - but that doesn’t stop them!
          <br />
          Saying that all Pakistanis are stereotypical would also be a
          stereotype; so we’ll just say that stereotyping is a prevalent issue
          in Pakistan. More often than not it is a subconscious process that not
          only costs a firm potentially great employee but also costs the
          candidate a good job (for invalid reasons out of their control!).
        </div>
        {/* <div className={classess.content_styling}>And for those of you who think that you’re too self-aware to ever be biased… think again! Bias within the human system is so unconscious that we fail to even recognise its presence. And of course, where there is bias, there is always a very strong chance of a mis-hire. But the very simple solution to this is Chatbots, particularly RecruitmentBot, which has been specially designed to ensure no bias makes its way to the screening process and that only the right employee is hired. 
        </div> */}
        <h3 className={classess.heading_three}>3. Nobody Wears a Halo</h3>
        <div className={classess.content_styling}>
          This hiring bias is closely linked to the confirmation bias, but much
          more specific. It happens when the recruiter doesn’t exactly do
          thorough research on the candidate and decides to heavily rely on one
          positive trait that the candidate possesses like where they live or
          what college they graduated from. This is very common in Pakistan; if
          you go to an interview and find out that the recruiter is from your
          old school, you begin to believe that undeniably the ball is in your
          court. Even on the receiving end, we expect such biases because they
          are so deeply ingrained in our way of thinking.
          <br />
          We focus on this ‘halo’ and start ignoring all other factors that go
          against our perception of the candidate. This makes us believe that
          this specific person is exactly what the organization needs, and we
          ignore all the red flags because they just seem that perfect.
        </div>
        {/* <div className={classess.content_styling}>For instance, RecruitmentBot has been modelled in a way that both candidates and employers benefit, and we know how de-humanizing the hiring process can be for candidates, whether it's through a system that's too mechanized or because of hiring managers that are too tough. This is  why despite the fact that our Chatbot is indeed a bot, it has been manufactured to recreate the human experience in such a way that candidates feel valued, at ease and still encouraged to perform their best.
              </div> */}
        {/* <center>
                <picture>
                    <source srcSet={candidates_webp} type="image/webp" />
                    <source srcSet={candidates} type="image/png" />
                    <img srcSet={candidates_webp} alt="Main Image"loading="lazy" className={classess.main_image_styling} />
                </picture>
            </center> */}
        <h3 className={classess.heading_three}>
          4. We’re All Suckers for Similarity
        </h3>
        <center>
          <picture>
            <source srcSet={slide1_webp} type="image/webp" />
            <source srcSet={slide1} type="image/png" />
            <img
              srcSet={slide1_webp}
              alt="Main Image"
              loading="lazy"
              className={classess.main_image_styling}
            />
          </picture>
        </center>
        <div className={classess.content_styling}>
          It’s not news that we humans like to stay comfortable surrounded by
          things and people that we like and feel a connection with. This
          preference doesn’t go away when it comes to the workplace, if
          anything, it probably becomes more intense. If you’re going to trust
          someone with the responsibilities of your company, and spend most days
          of the week with them, of course, you will want a feeling of
          familiarity. The similarity attraction bias occurs when recruiters end
          up taking it to a new extreme; they prefer hiring people who they feel
          are more similar to them - even if this similarity has nothing to do
          with job performance! Imagine preferring a job candidate based on
          something silly like both of you having the same favourite sitcom -
          how would that make sense?
        </div>
        {/* <br/>
            Hiring people is a strenuous task that demands insane amounts of time and effort. Recruiters should limit their extensively detailed questions and interviews for the shortlisted candidates rather than on every single applicant that applies for a position; it’s only the more efficient thing to do. 
           <br/>
           To make the recruiter’s time more available to make deep, meaningful connections with promising candidates, it’s smarter to allocate the initial testing to technology. AI-based technology, especially chatbots, are vital in this respect; they create an automated process for screening job candidates. And how does this benefit the recruiters and the organization? They can now fully invest in connecting with those people who are well along the stages of the hiring process - the people who are likely to be responsible for the future of the company! 
         
         <div className={classess.content_styling}>Practically speaking, every hiring manager would trade an arm and a leg to devise a hiring system that would make them perform their best. Yet, there is only so much the human mind can manage. RecruitmentBot on the other hand was invented to do what the human mind cannot. Not only does it do the mind-numbing part of the process, so that hiring managers can concentrate their energy on where it is needed the most, but the responses provided to the chatbot are made available to interviewers so they can streamline the process, even more, saving time, money and getting the best employee, all at once. 

          </div> */}
        <h3 className={classess.heading_three}>
          5. Your Candidate is Not Satan’s Spawn!
        </h3>
        <div className={classess.content_styling}>
          This bias is the exact opposite of the previously discussed ‘halo’
          effect; in this case, recruiters become fixated on one negative aspect
          of a candidate and refuse to move past it and consider the positive
          factors. Simply because they have a trait that unsettles the
          recruiter, they let it cloud their judgment and infiltrate the hiring
          process. I’m sure a hundred examples popped into your head as you just
          read this! Well, that’s exactly how common it is.
        </div>
        {/* <div className={classess.content_styling}>By keeping an open communication channel between recruiters and candidates, conversational recruiting ends up monumentally improving the candidate experience. Word of mouth holds the most value - if your candidates are happy, then you’ll get even better ones coming in soon after! From start to finish, recruiters need to keep candidates thoroughly engaged; it is a requirement rather than a plus point.  </div>
             <div className={classess.content_styling}>The RecruitmentBot in specific, through its intelligent technology and its cloud platform, not only produces the best matches, but it's easy to use and is the best way to organize the entire process through its easily deployable nature. And more importantly, it does exactly what it is needed to, making the whole idea of getting the perfect employee as simple as possible.   
             </div> */}

        <h3 className={classess.heading_three}>
          6. Adapting to the Crowd Under Pressure
        </h3>
        <div className={classess.content_styling}>
          Whether we choose to believe it or not, most humans are very
          susceptible to peer pressure. The mere thought of being ridiculed by
          our peers is enough to influence our choices, opinions, and even
          beliefs. More often than not the pressure to agree or disagree with
          something outweighs the responsibility to do the right thing.
          <br />
          If we look at matters from the context of the common Pakistani
          outlook, people with different opinions are rarely met with
          accommodating gestures or understanding eyes. So if three people on a
          panel believe that a candidate should be rejected for some specific
          reason, the fourth person might just agree with them out of fear of
          his real opinion being frowned upon. Rejection is scary, even for the
          most powerful of people.
        </div>

        <h3 className={classess.heading_three}>
          7. Biased Towards the Beautiful
        </h3>
        <center>
          <picture>
            <source srcSet={slide5_webp} type="image/webp" />
            <source srcSet={slide5} type="image/png" />
            <img
              srcSet={slide5_webp}
              alt="Main Image"
              loading="lazy"
              className={classess.main_image_styling}
            />
          </picture>
        </center>
        <div className={classess.content_styling}>
          WAs ignorant as it sounds, often people believe that beautiful people
          are more likely to be successful; it is simply how our brains have
          been trained to think. We strongly form the notion that the most
          handsome man or most gorgeous woman will do better in life, and as a
          result, we manifest it into existence. In Pakistan, where conventional
          beauty standards have only just begun to be questioned, this bias is
          fairly common. Yes, the educated people have started understanding
          that there is more to a person than their appearance, but this
          ideology has yet to penetrate the mindset of the common man. How will
          a recruiter hire a candidate when the candidate himself believes that
          appearance is a valid bias to hold against him?
        </div>

        <h3 className={classess.heading_three}>
          8. A Comparison is Never Fair
        </h3>
        <div className={classess.content_styling}>
          Parents in general (and Pakistani parents, in specific), are known for
          making unwarranted comparisons between their children and others. It’s
          something we all face and loathe, but you are mistaken if you think
          these unfair comparisons are only limited to your household!
          <br />
          Every individual candidate should be allowed to make an impact,
          irrespective of how others are performing. Unfortunately, recruiters
          often give in to the tendency of comparing and contrasting each resume
          with the one that precedes it. This creates a huge loophole in
          judgement, instead of seeing whether the candidate suits the job,
          recruiters end up getting derailed in the wrong direction.
        </div>

        <h3 className={classess.heading_three}>9. All Genders are Equal</h3>
        <center>
          <picture>
            <source srcSet={slide4_webp} type="image/webp" />
            <source srcSet={slide4} type="image/png" />
            <img
              srcSet={slide4_webp}
              alt="Main Image"
              loading="lazy"
              className={classess.main_image_styling}
            />
          </picture>
        </center>
        <div className={classess.content_styling}>
          To put it in less kind terms, what we’re talking about here is the
          sexist mindset. Pakistan is a developing country where even basic
          concepts like equality tend to be scrutinized more than they should
          be. For decades and centuries, our society has catered to the
          preservation of patriarchy; although the awareness advocating for
          equality is much more widespread now, it still hasn’t ceased to exist.
          <br />
          The gender bias in Pakistan majorly favours men over women, but in
          some situations, the bias is also vice versa. Many employers have a
          pre-existing bias in favour of one gender over the other before they
          even get the chance to assess the qualifications of the candidate. It
          could be for reasons like catering to their comfort, wanting to put in
          minimum effort, or even just blatant sexism. At the end of the day, a
          recruiter needs to understand that gender is the least important
          factor when it comes to analyzing skills and behaviour. A woman and a
          man both possess an equal chance of excelling at something, despite
          what orthodox Pakistani beliefs try to teach us.
        </div>

        <h3 className={classess.heading_three}>
          10. Intuition Does Not Help Make a Logical Decision
        </h3>
        <div className={classess.content_styling}>
          Recruiters are generally told to trust their gut, but they apply that
          rule at the wrong stage of the hiring process! A candidate should
          first be assessed on their skills, qualifications, behaviour, and
          experience, before moving on to other factors like intuition (which is
          heavily influenced by emotions).
          <br />A recruiter has the responsibility to do justice to all the
          applicants, if he chooses to base his decision on a whim or a gut
          feeling, then that speaks volume about how the organization will do in
          the long run.
        </div>

        <h2 className={classess.heading_two}>
          Using Technology to Overcome Hiring Bias
        </h2>
        {/* <h2 className={classess.heading_two}>
            How is conversational recruiting relevant in Pakistan?
            </h2> */}

        <center>
          <picture>
            <source srcSet={technology_webp} type="image/webp" />
            <source srcSet={technology} type="image/png" />
            <img
              srcSet={technology_webp}
              alt="Main Image"
              loading="lazy"
              className={classess.main_image_styling}
            />
          </picture>
        </center>
        <div className={classess.content_styling}>
          Biases in hiring are a human problem that has a human solution: using
          technology to streamline the recruitment process and make it virtually
          bias-free! Pakistan has always been slightly late to join in on the
          technological trends, but it is high time that it steps in to adopt
          the practice of solely relying on resumes. Some tools allow recruiters
          to sift through applications without knowing the age, gender, or even
          name of the potential employee. With the skilful development of AI
          tools, the hiring process could be revolutionized - there will finally
          be objective criteria for assessing skills and competencies. Using the
          resume free process proves to be very beneficial - companies who have
          adopted it acknowledges that some people who got shortlisted would
          never have made it through if they continued relying on the
          conventional resume practice. Equality is very important - especially
          when it comes to opportunities. No one has the right to take away
          someone’s chance at success just because they are biased!
        </div>
        <div className={classess.content_styling}>
          {" "}
          Seriously, think about it, where there are humans, there are
          preferences, and where there are preferences, there are biases. It is
          what it is. But does that mean we let this problem reside and ruin
          hiring all across the nation, especially in this technology-driven
          era? Absolutely not. And that’s what RecruitmentBot is here for, to
          aid technology’s role in revolutionizing the hiring process. When you
          have an automated chatbot, fed with carefully curated data about
          specified job roles, you have technology doing not only doing the
          tedious work, but also the work that humans can’t do without bias. And
          what do you get in return? Only the best. The best candidates, the
          best matches, the best speed and more importantly, the best
          environment, where everyone is equal and everyone is relevant.
        </div>
        {/* <div className={classess.content_styling}>And while change may be slow and gradual, RecruitmentBot is still out there, contributing in whatever way we possibly can. We not only know the issues of hiring systems, but because we are a locally based organization, we understand the nuances of the Pakistani hiring systems. Even when in situations where there is no bias, favouritism, nepotism etc, hiring managers tend to make the whole situation so eerie and daunting, completely forgetting how this may negatively affect the quality of hire. But that’s what RecruitmentBot is here for, we know what to ask and how to ask it in a way that is easy, conversational but also gets the job done.      </div>
             <h2 className={classess.heading_two}>
             What’s the best way to achieve this?
            </h2>
                 <center>
                <picture>
                    <source srcSet={achieve_webp} type="image/webp" />
                    <source srcSet={achieve} type="image/png" />
                    <img srcSet={achieve_webp} alt="Main Image"loading="lazy" className={classess.main_image_styling} />
                </picture>
            </center>
            <div className={classess.content_styling}>We have gone on and on about the magical effects of AI chatbots on the hiring process, but don’t worry we’re finally going to tell you how to go about the implementation! 
            RecruitmentBot is artificial intelligence and rule-based chatbot which is essentially the solution to most of your hiring problems. The chatbot asks candidates a series of automated questions, analyzes the responses within a minute, and calculates compatibility scores to establish which candidate is suitable for which position. And the best part? The results are based on all the things that genuinely matter in the recruitment process; potential, aptitude, skills and behaviour. 
             </div>
             <div className={classess.content_styling}>RecruitmentBot, being as objective in decision making as it can be, eliminates the chances of human error and bias. It allows candidates to feel like there is a more human and personal connection with the employers, and it allows employers to truly grasp the personality of who it is they are considering to hire. And to put a cherry on top… it’s so much easier!</div> */}
      </div>

      <Social url={`https://blogs.mycareerdreams.com${props.location.path}`} />
      <Footer />
    </React.Fragment>
  )
}
export default BiasesInHirings
