import React from "react"
import BiasesInHirings from "../components/recruitment_blogs/blogs/biases_in_hiring_process/biases_in_hiring"
import SEO from "../components/seo"

const Page = location => {
  return (
    <React.Fragment>
      <SEO
        title="How to Prevent Top 10 Biases in Hiring Process"
        description="What are the top 10 biases in hiring systems. How to prevent biases in
          Pakistan’s hiring process. Learn more."
        url="/biases-in-hiring-process/"
        image="https://blogs.mycareerdreams.com/blogs/hire_w.webp"
        type="article"
      />
      <BiasesInHirings location={location} />
    </React.Fragment>
  )
}
export default Page
